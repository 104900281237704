import Login from "../components/Login";

function Loginpage() {
  return (
    <>
      <Login />
    </>
  );
}
export default Loginpage;
