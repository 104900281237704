import Sidebar from "../components/Sidebar";
import "./iframe.css";

function Iframe() {
  return (
    <>
      <Sidebar headingText="Open Your Free Account" />
    </>
  );
}
export default Iframe;
