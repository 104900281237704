import { Helmet } from "react-helmet";
function Terms() {
  return (
    <>
      <Helmet>
        <title>Terms of Service - Trading Review Site</title>
        <meta
          name="description"
          content="Review our terms of service to understand your rights and responsibilities while using our trading review platform."
        />
      </Helmet>
      <h1>Terms of Use</h1>
      <p>
        Welcome to our website. These Terms of Use outline the rules and
        regulations for using our Site and Services. By accessing our Site, you
        agree to comply with these terms and understand your rights and
        responsibilities as a user.
      </p>

      <h2>Acknowledgment and Modification of the Agreement</h2>
      <p>
        If you do not agree with any of the terms outlined in this Agreement,
        please stop using the Site and its Services immediately.
      </p>
      <p>
        The Agreement may be updated periodically, with changes becoming
        effective 14 days after being posted on the Site, or sooner if required
        by law. By continuing to use the Site and its Services after such
        updates, you agree to be bound by the revised terms.
      </p>
      <p>
        To stay informed about the current terms, please review the Terms of
        Service and Privacy Policy regularly. We reserve the right to terminate
        or suspend access to the Services or the Site at our discretion, without
        any financial compensation.
      </p>

      <h2>Usage of the Site and Services</h2>
      <p>
        Access to the Site and its Services is limited to individuals who are at
        least 18 years old or of legal age in their jurisdiction. The Site is
        not intended for use by those who do not meet the legal age requirement.
        If you do not meet this criterion, please cease using the Site and its
        Services immediately.
      </p>

      <h2>The Services</h2>
      <p>
        Our Site provides information related to casino games and the gambling
        industry. These Services are offered free of charge and for
        informational purposes only. The Company does not operate any online
        gaming casinos or poker sites and does not accept any wagers or bets.
      </p>

      <h2>Intellectual Property Rights</h2>
      <p>
        The Company and its affiliates hold all rights to the content on the
        Site, including but not limited to software, data, text, graphics,
        designs, artwork, images, animations, videos, music, audio, and text.
        Altering or removing copyright or proprietary notices is strictly
        prohibited. The trademarks displayed on the Site are owned by the
        Company, its affiliates, or licensors, and all rights are reserved. Use
        of the Site Content and trademarks is permitted only in accordance with
        this Agreement.
      </p>

      <h2>User Content</h2>
      <p>
        You may submit User Content to the Site, including data, text, software,
        music, audio, images, videos, or messages. You are solely responsible
        for your User Content, and the Company and its affiliates are not liable
        for it. By submitting User Content, you grant the Company and its
        affiliates a perpetual, irrevocable, worldwide license to use,
        reproduce, modify, distribute, display, and create derivative works from
        such content without any compensation. You also waive any moral rights
        in your User Content. The Company reserves the right to monitor, edit,
        or remove User Content at any time without notice. Please be cautious
        when sharing personally identifiable information.
      </p>

      <h2>Prohibited Activities</h2>
      <p>
        As a user of the Site, you agree not to engage in prohibited activities,
        including but not limited to:
      </p>
      <ul>
        <li>Collecting or accessing personal information from other users;</li>
        <li>
          Using the Site, its Services, or Site Content for illegal purposes;
        </li>
        <li>
          Copying, redistributing, publishing, reverse-engineering, or
          disclosing Site information to third parties;
        </li>
        <li>Using automated means to gather data from the Site;</li>
        <li>Distributing malicious code;</li>
        <li>
          Engaging in illegal, harmful, or threatening behavior that violates
          applicable laws.
        </li>
      </ul>

      <h2>Your Undertakings and Representations</h2>
      <p>
        By accessing the Site, you affirm that you meet the legal age
        requirement and intend to use the Site and its Services for personal and
        non-commercial purposes only. You agree to abide by all applicable laws
        and not to impersonate others. You also waive the right to participate
        in class actions against the Company. Any disputes will be resolved
        through arbitration.
      </p>

      <h2>Third-Party Content</h2>
      <p>
        The Site may contain links to Third-Party Content for reference only. We
        are not responsible for the content of such third-party sites, and you
        are solely responsible for your engagement with any Third-Party Content.
      </p>
    </>
  );
}
export default Terms;
