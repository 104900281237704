import React from "react";
import "./index.css";

function TradeDigi() {
  return (
    <>
      <div className="tradedigi">
        <h2 className="sect-heading">
          Trade Digital Assets with a Reputable Broker
        </h2>
        <p>
          Navigate the complexities of the forex market with a broker dedicated
          to offering the finest trading experience – Quantum Code Ai,
          showcasing our unique features:
        </p>
        <div className="tradedigisec">
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Filled"
                  viewBox="0 0 24 24"
                >
                  <path d="M18.581,2.14,12.316.051a1,1,0,0,0-.632,0L5.419,2.14A4.993,4.993,0,0,0,2,6.883V12c0,7.563,9.2,11.74,9.594,11.914a1,1,0,0,0,.812,0C12.8,23.74,22,19.563,22,12V6.883A4.993,4.993,0,0,0,18.581,2.14ZM16.718,9.717l-4.272,4.272a1.873,1.873,0,0,1-1.335.553h-.033a1.872,1.872,0,0,1-1.345-.6l-2.306-2.4A1,1,0,1,1,8.868,10.16L11.112,12.5,15.3,8.3a1,1,0,0,1,1.414,1.414Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">Transparent trading environment</div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m6.5,0C2.91,0,0,2.91,0,6.5s2.91,6.5,6.5,6.5,6.5-2.91,6.5-6.5S10.09,0,6.5,0Zm1.315,11h-.315c0,.552-.448,1-1,1s-1-.448-1-1c-1.113,0-2.087-.61-2.605-1.513-.38-.662.11-1.487.874-1.487.359,0,.686.196.869.506.174.296.495.494.862.494h2.35c.334,0,.629-.251.649-.585.02-.324-.206-.602-.52-.655l-3.286-.548c-1.277-.213-2.203-1.312-2.192-2.61.011-1.451,1.234-2.603,2.685-2.603h.315c0-.552.448-1,1-1s1,.448,1,1c1.113,0,2.087.61,2.605,1.513.38.662-.11,1.487-.874,1.487-.359,0-.686-.196-.869-.506-.174-.295-.495-.494-.862-.494h-2.376c-.36,0-.649.305-.622.67.021.292.255.525.544.574l3.261.544c1.277.213,2.203,1.312,2.193,2.61-.011,1.451-1.234,2.603-2.685,2.603Zm15.181,2.62l-5,2.857-5-2.857,3.511-2.007c.922-.527,2.055-.527,2.977,0l3.511,2.007Zm-10.996,1.803l5,2.857v5.72l-3.489-1.994c-.935-.534-1.511-1.528-1.511-2.605v-3.979Zm7,2.857l5-2.857v3.979c0,1.077-.577,2.07-1.511,2.605l-3.489,1.994v-5.72Zm-9.323.507c.375.375.375.983,0,1.358l-3.038,3.038c-.605.605-1.639.176-1.639-.679v-1.504h-.5c-2.481,0-4.5-2.019-4.5-4.5,0-.828.671-1.5,1.5-1.5s1.5.672,1.5,1.5.673,1.5,1.5,1.5h.5v-1.571c0-.855,1.034-1.284,1.639-.679l3.038,3.038Zm4.646-13.575c-.375-.375-.375-.983,0-1.358l3.038-3.038c.605-.605,1.639-.177,1.639.679v1.504h.5c2.481,0,4.5,2.019,4.5,4.5,0,.828-.671,1.5-1.5,1.5s-1.5-.672-1.5-1.5-.673-1.5-1.5-1.5h-.5v1.571c0,.856-1.034,1.284-1.639.679l-3.038-3.038Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">
              Diverse assets across 250+ platforms
            </div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m14,9.5c0,1.103-.897,2-2,2s-2-.897-2-2,.897-2,2-2,2,.897,2,2Zm7.168-1.283c-.239-.431-.557-.761-.918-.995-.644-.416-.987-1.181-.817-1.928.097-.429.091-.897-.048-1.381-.246-.859-.946-1.559-1.805-1.805-.484-.139-.952-.145-1.38-.048-.748.17-1.513-.172-1.929-.817-.233-.361-.564-.679-.994-.918-.782-.433-1.771-.433-2.553,0-.431.239-.761.557-.994.918-.416.644-1.181.987-1.929.817-.428-.097-.896-.091-1.38.048-.859.246-1.559.946-1.805,1.805-.139.484-.145.953-.048,1.381.17.748-.173,1.513-.817,1.928-.362.233-.679.564-.918.995-.219.394-.325.842-.323,1.289-.002.447.105.894.323,1.289.239.431.557.761.918.995.644.416.987,1.181.817,1.928-.097.429-.091.897.048,1.382.246.859.946,1.559,1.805,1.805.484.139.952.145,1.38.048.748-.17,1.513.172,1.929.817.233.361.563.679.994.918.782.433,1.772.433,2.553,0,.431-.239.761-.557.994-.918.416-.644,1.181-.987,1.929-.817.428.097.896.091,1.38-.048.859-.246,1.559-.946,1.805-1.805.139-.484.145-.953.048-1.382-.17-.748.173-1.513.817-1.928.362-.233.679-.564.918-.995.219-.394.325-.842.323-1.289.002-.447-.105-.894-.323-1.289Zm-9.179,5.285c-2.206,0-4-1.794-4-4s1.794-4,4-4,4,1.794,4,4-1.794,4-4,4Zm-3.888,5.43c-.726.142-1.496.106-2.231-.105-1.426-.408-2.576-1.506-3.076-2.894l-2.306,2.223c-.972.938-.405,2.584.939,2.723l1.765.182.218,1.568c.179,1.291,1.751,1.828,2.683.917l3.401-3.296c-.553-.355-1.033-.784-1.393-1.318Zm6.406,1.318l3.401,3.296c.932.911,2.504.374,2.683-.917l.218-1.568,1.765-.182c1.344-.139,1.911-1.785.939-2.723l-2.306-2.223c-.5,1.388-1.65,2.486-3.076,2.894-.735.211-1.505.247-2.231.105-.36.534-.84.963-1.393,1.318Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">Secure transaction encryption</div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m20.5,0H3.5C1.57,0,0,1.57,0,3.5v18.5h14v-3H3V7h18v1h3V3.5c0-1.93-1.57-3.5-3.5-3.5ZM4.5,5c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm5,0c-.828,0-1.5-.672-1.5-1.5s.672-1.5,1.5-1.5,1.5.672,1.5,1.5-.672,1.5-1.5,1.5Zm14.5,9.5v.5h-3v-.5c0-.276-.224-.5-.5-.5h-1c-.276,0-.5.224-.5.5,0,.294.177.555.45.665l2.215.886c1.419.567,2.335,1.922,2.335,3.45,0,1.58-1.059,2.903-2.5,3.337v1.163h-3v-1.163c-1.441-.434-2.5-1.757-2.5-3.337v-.5h3v.5c0,.276.224.5.5.5h1c.276,0,.5-.224.5-.5,0-.294-.177-.555-.45-.665l-2.215-.886c-1.419-.567-2.335-1.922-2.335-3.45,0-1.58,1.059-2.903,2.5-3.337v-1.163h3v1.163c1.441.434,2.5,1.757,2.5,3.337ZM6.757,9c-.757,0-1.281.587-1.385,1.167l-1.372,6.833h1.469l.275-1.474h2.006l.262,1.474h1.488l-1.397-6.807c-.12-.668-.589-1.192-1.346-1.192Zm-.645,5l.624-3.295.638,3.295h-1.261Zm6.188-5h-2.3v8h2.3c1.269,0,2.3-1.031,2.3-2.3v-3.4c0-1.269-1.031-2.3-2.3-2.3Zm.7,5.7c0,.386-.314.7-.7.7s-.7,0-.7,0v-4.802s.314,0,.7,0,.7.314.7.7v3.4Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">Multi-platform market access</div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="M23,22H5a3,3,0,0,1-3-3V1A1,1,0,0,0,0,1V19a5.006,5.006,0,0,0,5,5H23a1,1,0,0,0,0-2Z" />
                  <path d="M6,20a1,1,0,0,0,1-1V12a1,1,0,0,0-2,0v7A1,1,0,0,0,6,20Z" />
                  <path d="M10,10v9a1,1,0,0,0,2,0V10a1,1,0,0,0-2,0Z" />
                  <path d="M15,13v6a1,1,0,0,0,2,0V13a1,1,0,0,0-2,0Z" />
                  <path d="M20,9V19a1,1,0,0,0,2,0V9a1,1,0,0,0-2,0Z" />
                  <path d="M6,9a1,1,0,0,0,.707-.293l3.586-3.586a1.025,1.025,0,0,1,1.414,0l2.172,2.172a3,3,0,0,0,4.242,0l5.586-5.586A1,1,0,0,0,22.293.293L16.707,5.878a1,1,0,0,1-1.414,0L13.121,3.707a3,3,0,0,0-4.242,0L5.293,7.293A1,1,0,0,0,6,9Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">
              Fast order execution at 0.04 sec
            </div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m24.002,5.512c.001-.978-.579-1.823-1.479-2.153L14.621.459c-1.657-.607-3.48-.611-5.136-.007L1.486,3.362C.584,3.691.001,4.536.002,5.514c0,.979.584,1.823,1.486,2.15l7.954,2.886c.823.299,1.688.448,2.552.448s1.728-.149,2.55-.448l7.456-2.705v4.154c0,.552.448,1,1,1s1-.448,1-1c0,0,.002-6.483.002-6.488ZM2.203,16.041c2.087.523,6.052,1.652,8.797,1.904v4.11c-2.745.252-6.711,1.382-8.797,1.904-1.121.281-2.203-.571-2.203-1.727v-4.464c0-1.156,1.082-2.008,2.203-1.727Zm21.797,1.727v4.464c0,1.156-1.082,2.008-2.203,1.727-2.087-.523-6.052-1.652-8.797-1.904v-4.11c2.745-.252,6.711-1.382,8.797-1.904,1.121-.281,2.203.571,2.203,1.727Zm-19-3.071v-3.631l3.759,1.364c2.074.753,4.389.754,6.467,0l3.773-1.369v3.635c-1.954.501-4.5,1.332-7,1.332s-5.046-.831-7-1.332Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">
              Comprehensive trading education hub
            </div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  id="Layer_1"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="Layer 1"
                >
                  <path d="m24 4.5a1 1 0 0 1 -2 0 2.59 2.59 0 0 0 -2.785-2.5 1 1 0 0 1 0-2 4.6 4.6 0 0 1 4.785 4.5z" />
                  <path d="m2 4.5a2.59 2.59 0 0 1 2.785-2.5 1 1 0 0 0 0-2 4.6 4.6 0 0 0 -4.785 4.5 1 1 0 0 0 2 0z" />
                  <path d="m20.319 20.176a10.98 10.98 0 0 0 -7.319-18.125v-1.051a1 1 0 0 0 -2 0v1.051a10.98 10.98 0 0 0 -7.319 18.125 3.024 3.024 0 0 0 -1.681 2.824 1 1 0 0 0 2 0 1.2 1.2 0 0 1 1.11-1.229.932.932 0 0 0 .2-.062 10.924 10.924 0 0 0 13.39 0 1.033 1.033 0 0 0 .182.064 1.2 1.2 0 0 1 1.118 1.227 1 1 0 0 0 2 0 3.024 3.024 0 0 0 -1.681-2.824zm-4.612-4.469a1 1 0 0 1 -1.414 0l-3-3a1 1 0 0 1 -.293-.707v-5a1 1 0 0 1 2 0v4.586l2.707 2.707a1 1 0 0 1 0 1.414z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">
              Streamlined three-step onboarding
            </div>
          </div>
          <div className="tradedigisec1">
            <div className="tradedigisvgg">
              <div className="tradedigisvg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  data-name="Layer 1"
                  viewBox="0 0 24 24"
                >
                  <path d="m18,12c-3.314,0-6,2.686-6,6s2.686,6,6,6,6-2.686,6-6-2.686-6-6-6Zm3.683,5.712l-2.703,2.614c-.452.446-1.052.671-1.653.671s-1.203-.225-1.663-.674l-1.354-1.332c-.395-.387-.4-1.02-.014-1.414.386-.395,1.019-.401,1.414-.014l1.353,1.331c.144.142.38.139.522-.002l2.713-2.624c.397-.381,1.031-.37,1.414.029.382.398.369,1.031-.029,1.414ZM8.344,7.226L14.646,1.073c1.51-1.173,3.618-1.393,5.308-.548,0,0,1.511,1.089,3.205,1.395.483.087.841.492.841.982v9.818c-1.466-1.665-3.607-2.721-6-2.721-.572,0-1.13.063-1.668.177l-2.822-2.358,1.181-1.097c.399-.382.413-1.015.031-1.414-.382-.399-1.015-.413-1.414-.031l-3.618,3.429c-.207.208-.493.315-.788.29-.298-.024-.56-.175-.739-.425-.274-.38-.19-.975.181-1.347Zm2.127,13.457c-.303-.126-.599-.279-.871-.482l-6.133-4.6c-.519-.39-1.151-.6-1.8-.6h-.667c-.552,0-1-.448-1-1V2.937c0-.508.379-.931.884-.988,1.357-.151,2.579-.696,3.829-1.424.687-.344,1.456-.525,2.227-.525,1.28,0,2.471.461,3.39,1.298l.617.593-4.007,3.911c-1.073,1.072-1.244,2.767-.398,3.938.52.723,1.461,1.259,2.444,1.259.793,0,1.554-.312,2.104-.863l.967-.925,2.113,1.765c-2.483,1.358-4.168,3.993-4.168,7.023,0,.942.171,1.843.471,2.682Z" />
                </svg>
              </div>
            </div>
            <div className="tradedigidesc">Accessible analytical tools</div>
          </div>
        </div>
        <p>
          Embrace your trading aspirations and reach new heights in the market
          with Quantum Code Ai as your trusted Digital Assets broker.
        </p>
      </div>
    </>
  );
}

export default TradeDigi;
